import { Button } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  setShowDocumentManagers,
  setShowSidebar,
} from '../../../actions/showLayoutActions'
import { useStore } from '../../../useStore'
import ArrowLeftToLine from '../../assets/ArrowLeftToLine'
import CollapsibleLayout from '../../shared/CollapsibleLayout'

export const MainChat = observer(() => {
  const store = useStore()

  return (
    <CollapsibleLayout
      navBarClassName="h-[77px] md:min-w-1/2"
      showLeftControlButton={!store.showLayouts.showSidebar}
      handleLeftControlButtonClick={() => {
        setShowSidebar(store, true)
      }}
      headerLeftComponent={
        <span className="text-lg font-semibold text-txt-default-default">
          {/* TODO: add the real chat title component*/}
          Chat Name
        </span>
      }
      headerRightComponent={
        !store.showLayouts.showDocumentManager ? (
          <Button
            variant="outlined"
            className="border-bgCol-neutral-secondaryHover py-1 px-2 flex justify-center items-center"
            onClick={() => {
              setShowDocumentManagers(store, true)
            }}
          >
            <ArrowLeftToLine />
            <span className="pl-1 text-sm font-medium text-txt-default-default">
              Documents
            </span>
          </Button>
        ) : null
      }
    >
      {/*  TODO: add Chat Content and Chat Input*/}
    </CollapsibleLayout>
  )
})

export default MainChat

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import FolderIcon from '../assets/FolderIcon'
import ThreeDotsIcon from '../assets/ThreeDotsIcon'
import DeleteMatterModal from '../DeleteMatterModal'
import SettingsModal from '../SettingsModal'

const LegacyMattersListItem = ({
  matter,
  isSelected,
  onMatterClick,
  onDeleteClick,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isDeleteMatterModalOpen, setIsDeleteMatterModalOpen] = useState(false)

  const handleSettingsClick = (e) => {
    e.stopPropagation()
    setIsSettingsOpen(true)
    setIsMenuOpen(false)
  }

  const handleDeleteClick = async (e) => {
    e.stopPropagation()
    setIsMenuOpen(false)
    setIsDeleteMatterModalOpen(true)
  }

  const handleMatterClick = () => {
    onMatterClick(matter)
  }

  const folderColor = () => {
    if (matter.assigned) {
      if (isSelected) {
        return {
          stroke: '#111827',
          backgroundColor: '#0D1DC0',
        }
      } else {
        return {
          stroke: '#9CA3AF',
          backgroundColor: '#A899F5',
        }
      }
    } else {
      if (isSelected) {
        return {
          stroke: '#4F46E5',
          backgroundColor: 'bg-bgCol-brand-secondary',
        }
      } else {
        return {
          stroke: '#4F46E5',
          backgroundColor: '#FFFFFF',
        }
      }
    }
  }

  return (
    <div
      className={`mx-2 max-w-64 group relative cursor-pointer p-2 ${isSelected ? 'bg-bgCol-brand-secondary rounded-lg text-txt-brand-default' : ''}`}
      onMouseLeave={() => setIsMenuOpen(false)} // Close menu on mouse leave
    >
      <div
        className="h-7 flex items-center justify-between"
        onClick={handleMatterClick}
      >
        <div className="flex items-start space-x-2">
          <div className="pl-1">
            <FolderIcon {...folderColor()} width={18} height={18} />
          </div>
          <div className="flex flex-col max-w-[170px]">
            <span className="truncate text-sm block whitespace-nowrap overflow-hidden">
              {matter.title}
            </span>
            {matter.file_number && (
              <p
                className={`text-xs ${isSelected ? 'text-txt-brand-secondary' : 'text-txt-neutral-secondary'}`}
              >
                {matter.file_number}
              </p>
            )}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsMenuOpen(!isMenuOpen)
          }}
          className={`h-full opacity-0 group-hover:opacity-100 transition-opacity duration-150`}
        >
          <ThreeDotsIcon />
        </button>
      </div>
      {isMenuOpen && (
        <div
          className="group absolute right-0 top-6 mt-2 w-32 bg-white rounded-lg shadow-md z-50 text-black text-sm"
          onClick={(e) => e.stopPropagation()} // Prevent closing on click inside
        >
          <ul className="p-1">
            <li
              onClick={handleSettingsClick}
              className="block px-3 py-2 cursor-pointer hover:bg-gray-50 rounded"
            >
              <span>View details</span>
            </li>
            <li
              onClick={handleDeleteClick}
              className="block mt-1 px-3 py-2 cursor-pointer hover:bg-gray-50 text-txt-danger-default rounded"
            >
              <span>Delete</span>
            </li>
          </ul>
        </div>
      )}
      {isSettingsOpen && (
        <SettingsModal
          matter={matter}
          onClose={() => setIsSettingsOpen(false)}
        />
      )}
      {isDeleteMatterModalOpen && (
        <DeleteMatterModal
          matter={matter}
          onClose={() => setIsDeleteMatterModalOpen(false)}
          onDelete={onDeleteClick}
        />
      )}
    </div>
  )
}

LegacyMattersListItem.propTypes = {
  matter: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onMatterClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
}

export default LegacyMattersListItem

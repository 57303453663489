import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from '././chat_layout/Sidebar'
import { setMatters } from '../../actions/matterActions'
import {
  is2xLSize,
  isLgSize,
  isMdSize,
  setShowDocumentManagers,
  setShowSidebar,
} from '../../actions/showLayoutActions'
import { useScreenSize } from '../../hooks/useScreenSize'
import StoreContext from '../../StoreContext'
import { useStore } from '../../useStore'
const ChatLayout: React.FC = () => {
  const store = useStore()
  const { screenWidth } = useScreenSize()

  const [mattersLoaded, setMattersLoaded] = useState(false)

  useEffect(() => {
    setMatters(store).then(() => setMattersLoaded(true))
    //   eslint-disable-next-line react-hooks/exhaustive-deps -- this is correct
  }, [])

  useEffect(() => {
    if (isMdSize(screenWidth)) {
      setShowSidebar(store, true)
    } else {
      setShowSidebar(store, false)
    }

    if (is2xLSize(screenWidth)) {
      setShowDocumentManagers(store, true)
    } else if (!isLgSize(screenWidth)) {
      setShowDocumentManagers(store, false)
    }
  }, [screenWidth])

  return (
    <StoreContext.Provider value={store}>
      <div className="flex h-screen overflow-hidden">
        <div className="flex flex-col">
          <Sidebar />
        </div>
        <div className="flex-1 flex-col">{mattersLoaded && <Outlet />}</div>
      </div>
    </StoreContext.Provider>
  )
}

export default ChatLayout

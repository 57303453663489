import { observer } from 'mobx-react-lite'
import React from 'react'

import ChatInput from './ChatInput'
import InitialChatHeader from './main_chat/InitialChatHeader'
import { InitialChatSuggestions } from './main_chat/InitialChatSuggestions'
import InitialWorkflow from './workflow/InitialWorkflow'

const HomeScreen = observer(() => {
  return (
    <>
      <div className="flex-1 flex flex-col relative overflow-y-auto">
        <InitialChatHeader />
        <div className="flex-1 pt-2 overflow-y-auto chat-container" />
        <div className="bg-white p-6 pb-2">
          <InitialChatSuggestions />
          <ChatInput />
          <div className="mt-2 text-center text-xs text-txt-neutral-secondary">
            Alexi is a conversational tool designed to assist and provide
            information to the best of its abilities. Consider verifying
            important information.
          </div>
        </div>
      </div>

      <InitialWorkflow />
    </>
  )
})

export default HomeScreen

import PropTypes from 'prop-types'
import React from 'react'

import UploadSection from './UploadSection'
import { useStore } from '../../../useStore'
import FolderIcon from '../../assets/FolderIcon'
import EnterpriseDocument from '../../workflow/EnterpriseDocument'

const FilesAndFolders = ({ currentFolder }) => {
  const store = useStore()

  const navigateToRootFolder = () => {
    store.currentFolder = store.folders
  }

  const renderBreadcrumbs = () => {
    const pathSegments =
      currentFolder?.full_path.split('/').filter(Boolean) || []
    // eslint-disable-next-line unused-imports/no-unused-vars
    let cumulativePath = ''

    return (
      <nav className="mb-4 flex space-x-2 text-sm">
        {pathSegments.map((segment, index) => {
          cumulativePath += `/${segment}`
          return (
            <React.Fragment key={index}>
              <button
                onClick={() => navigateToRootFolder()}
                className="text-txt-brand-default hover:underline"
              >
                {segment || 'Root'}
              </button>
              {index < pathSegments.length - 1 && <span>/</span>}
            </React.Fragment>
          )
        })}
      </nav>
    )
  }

  const shouldRenderUploadSection =
    currentFolder?.folder_type === 'user_uploaded' &&
    currentFolder?.documents?.length === 0 &&
    currentFolder?.sub_folders?.length === 0

  const showUploadIcon = currentFolder?.folder_type === 'user_uploaded'

  return (
    <div className="p-4 h-full w-full">
      {renderBreadcrumbs()}

      {/* Upload Section */}
      {shouldRenderUploadSection && <UploadSection />}

      {!shouldRenderUploadSection && (
        <div className="overflow-x-auto w-full">
          <table className="w-full table-auto border-collapse divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                  Name
                </th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                  Type
                </th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                  Description
                </th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                  Modified
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {currentFolder?.sub_folders.map((subFolder) => (
                <tr
                  key={subFolder.id}
                  className="hover:bg-gray-50 cursor-pointer text-sm"
                  onClick={() => (store.currentFolder = subFolder)}
                >
                  <td className="px-4 py-2 text-txt-default-default">
                    <div className="flex items-center space-x-2">
                      <FolderIcon
                        stroke="#111827"
                        backgroundColor=""
                        width={18}
                        height={18}
                      />
                      <span>{subFolder.title}</span>
                    </div>
                  </td>
                  <td className="px-4 py-2">Folder</td>
                  <td className="px-4 py-2">-</td>
                  <td className="px-4 py-2">-</td>
                </tr>
              ))}
              {currentFolder?.documents.map((doc) => (
                <EnterpriseDocument key={doc.id} doc={doc} />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Upload Icon */}
      {showUploadIcon && (
        <div className="absolute top-4 right-4">
          <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-400">
            Upload
          </button>
        </div>
      )}
    </div>
  )
}

FilesAndFolders.propTypes = {
  currentFolder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    full_path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    parent_id: PropTypes.string,
    folder_type: PropTypes.string.isRequired,
    sub_folders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        parent_id: PropTypes.string,
      })
    ),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        content_type: PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired,
        generated_name: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
      })
    ),
  }),
}

export default FilesAndFolders

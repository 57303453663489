import React from 'react'

interface DocumentIconProps {
  stroke?: string
}

const DocumentIcon: React.FC<DocumentIconProps> = ({ stroke = '#5133EA' }) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M11 1V5.44444C11 5.73913 11.1171 6.02174 11.3254 6.23012C11.5338 6.43849 11.8164 6.55556 12.1111 6.55556H16.5556"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M14.3333 21H3.22222C2.63285 21 2.06762 20.7659 1.65087 20.3491C1.23413 19.9324 1 19.3671 1 18.7778V3.22222C1 2.63285 1.23413 2.06762 1.65087 1.65087C2.06762 1.23413 2.63285 1 3.22222 1H11L16.5556 6.55556V18.7778C16.5556 19.3671 16.3214 19.9324 15.9047 20.3491C15.4879 20.7659 14.9227 21 14.3333 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Icon"
        d="M13 12.5H5M13 16H5M7 9H5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default DocumentIcon

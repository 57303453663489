import React, { useState } from 'react'

import BackgroundFactsForm from './BackgroundFactsForm'
import CancelButton from './CancelButton'
import ResearchQuestionForm from './ResearchQuestionForm'
import StateJurisdictionSelector from './StateJurisdictionSelector'
import SubmitButton from './SubmitButton'
import {
  cancelMemoRequest,
  updateMemoRequest,
} from '../../../apis/memoRequestApi'
import { useStore } from '../../../useStore'

interface MemoFormProps {
  responseAdditionalContext: {
    research_facts?: string
    research_question?: string
    jurisdiction?: string
    state?: string
  }
}

const MemoForm: React.FC<MemoFormProps> = ({ responseAdditionalContext }) => {
  const store = useStore()
  const handleSubmit = async () => {
    const data = {
      research_facts: researchFacts,
      research_question: researchQuestion,
      jurisdiction: jurisdiction,
      state: state,
    }
    await updateMemoRequest(store, data)
  }

  const handleCancel = async () => {
    await cancelMemoRequest(store)
  }

  const normalizeValue = (value?: string): string => {
    return value === 'none' || value === undefined || value === 'null'
      ? ''
      : value
  }

  const [researchFacts, setResearchFacts] = useState(
    normalizeValue(responseAdditionalContext.research_facts)
  )
  const [researchQuestion, setResearchQuestion] = useState(
    normalizeValue(responseAdditionalContext.research_question)
  )
  const [jurisdiction, setJurisdiction] = useState('')
  const [state, setState] = useState('')

  return (
    <div className="my-4 rounded-lg shadow-sm">
      <div>
        <ResearchQuestionForm
          inputValue={researchQuestion}
          setInputValue={setResearchQuestion}
        />

        <BackgroundFactsForm
          inputValue={researchFacts}
          setInputValue={setResearchFacts}
        />

        <StateJurisdictionSelector
          jurisdiction={jurisdiction}
          setJurisdiction={setJurisdiction}
          state={state}
          setState={setState}
          additionalContext={responseAdditionalContext}
        />
      </div>

      <div className="flex justify-end space-x-2 mt-2">
        <CancelButton handleCancel={handleCancel} />
        <SubmitButton
          disabled={!researchFacts && !researchQuestion}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  )
}

export default MemoForm

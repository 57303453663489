import { runInAction } from 'mobx'

import { findMessage } from './messagesActions'
import { fetchReplacementUrl } from '../apis/caselawApi'

// Replaces FastCase URLs in the message content after the stream ends
export const replaceFastCaseUrls = async (store, matterId, messageId) => {
  if (!store.pendingUrlReplacements?.get(messageId)) {
    return
  }

  const message = findMessage(store, matterId, messageId)
  if (!message) {
    return
  }

  const pendingReplacements = store.pendingUrlReplacements.get(messageId)
  let updatedContent = message.content

  for (const [urlToReplace, replacementUrl] of pendingReplacements) {
    updatedContent = processReplacement(
      urlToReplace,
      replacementUrl,
      updatedContent,
      pendingReplacements
    )
  }

  // Update message content and clean up
  runInAction(() => {
    message.content = updatedContent
  })
  store.pendingUrlReplacements.delete(messageId)
}

// Handles individual URL replacement
const processReplacement = (
  urlToReplace,
  replacementUrl,
  content,
  pendingReplacements
) => {
  try {
    content = content.replace(urlToReplace, replacementUrl)
    pendingReplacements.delete(urlToReplace) // Remove processed URL
  } catch (error) {
    console.error(`Failed to replace URL: ${urlToReplace}`, error)
  }
  return content
}

// Identifies FastCase URLs in the streaming content
export const identifyFastCaseUrls = async (store, message) => {
  initializeReplacements(store, message.id)

  const contentArray = prepareContentArray(
    store.chunkBuffer,
    message.content.parts.join('')
  )
  const processedMessage = await processContentArray(
    contentArray,
    store,
    message
  )

  // Update chunk buffer with leftover data
  store.chunkBuffer = processedMessage.accumulatedArray.join('')

  // Return processed message with updated content
  return {
    ...message,
    content: {
      ...message.content,
      parts: [processedMessage.updatedContent + store.chunkBuffer],
    },
  }
}

// Initializes the replacement map for the given message
const initializeReplacements = (store, messageId) => {
  if (!store.pendingUrlReplacements) {
    store.pendingUrlReplacements = new Map()
  }
  if (!store.pendingUrlReplacements.has(messageId)) {
    store.pendingUrlReplacements.set(messageId, new Map())
  }
}

// Prepares the content array by combining the chunk buffer and the new content
const prepareContentArray = (chunkBuffer, newContent) => {
  return [...(chunkBuffer || '').split(''), ...newContent.split('')]
}

// Processes the content array to identify and handle URLs
const processContentArray = async (contentArray, store, message) => {
  const urlRegex = /https:\/\/fc7\.fastcase\.com\/results\?docUid=\d+\)/
  const partialUrlRegex = /https:\/\/fc7\.fastcase\.com\/results\?docUid=\d*/
  let accumulatedArray = []
  let updatedContent = ''

  for (const char of contentArray) {
    accumulatedArray.push(char)
    const currentString = accumulatedArray.join('')

    if (urlRegex.test(currentString)) {
      updatedContent = await handleFullMatch(
        currentString,
        updatedContent,
        accumulatedArray,
        store,
        message
      )
    } else if (char === ')') {
      updatedContent = handleUnmatchedParenthesis(
        accumulatedArray,
        updatedContent
      )
    } else if (!partialUrlRegex.test(currentString)) {
      updatedContent = handleNonMatchingContent(
        accumulatedArray,
        updatedContent,
        currentString
      )
    }
  }

  return { updatedContent, accumulatedArray }
}

// Handles a full URL match
const handleFullMatch = async (
  currentString,
  updatedContent,
  accumulatedArray,
  store,
  message
) => {
  const urlToReplace = currentString.slice(0, -1) // Remove closing parenthesis
  if (!store.fetchedUrls.has(urlToReplace)) {
    try {
      const replacementUrl = await fetchReplacementUrl(urlToReplace, message.id)
      store.pendingUrlReplacements
        .get(message.id)
        .set(urlToReplace, replacementUrl)
      store.fetchedUrls.add(urlToReplace)
    } catch (error) {
      console.error(`Failed to fetch replacement URL: ${urlToReplace}`, error)
    }
  }
  accumulatedArray.length = 0 // Clear the accumulator
  return updatedContent + currentString
}

// Handles unmatched closing parentheses
const handleUnmatchedParenthesis = (accumulatedArray, updatedContent) => {
  updatedContent += accumulatedArray.join('')
  accumulatedArray.length = 0 // Reset the accumulator
  return updatedContent
}

// Handles non-matching content
const handleNonMatchingContent = (
  accumulatedArray,
  updatedContent,
  currentString
) => {
  if (currentString.endsWith('https://') && accumulatedArray.length > 8) {
    updatedContent += accumulatedArray.join('')
    accumulatedArray.length = 0 // Reset accumulator for new `https://`
    accumulatedArray.push('h', 't', 't', 'p', 's', ':', '/', '/')
  }
  return updatedContent
}

export const updateMemoRequest = async (store, data) => {
  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  return await fetch(
    `/api/client/v1/memo_requests/${store.selectedMatter.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({
        memo_request: {
          data: data,
        },
      }),
    }
  )
}

export const cancelMemoRequest = async (store) => {
  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  return await fetch(
    `/api/client/v1/memo_requests/${store.selectedMatter.id}/cancel_memo`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    }
  )
}

import axios from 'axios'

export const fetchReplacementUrl = async (documentUrl, messageId) => {
  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')

    const headers = {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    }

    const response = await axios.post(
      '/api/client/v1/caselaw/retrieve_universal_id',
      { documentUrl, messageId },
      { headers }
    )

    if (response.status !== 200) {
      throw new Error(
        `Failed to fetch replacement URL. Status code: ${response.status}`
      )
    }

    return response.data
  } catch (error) {
    console.error(`Error fetching replacement URL: ${documentUrl}`, error)
    throw error
  }
}

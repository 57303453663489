import React from 'react'

import DocumentIcon from '../assets/DocumentIcon'

const DragAndDropCard = () => {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-90 rounded-lg">
      <div className="relative flex items-center justify-center w-16 h-16 mb-4">
        {/* Rotated Background Card */}
        <div className="absolute w-16 h-16 bg-bgCol-brand-tertiary rounded-lg rotate-15 -top-1 -right-1 z-0"></div>

        {/* Main Card with Icon */}
        <div className="relative flex items-center justify-center w-16 h-16 bg-bgCol-brand-default rounded-lg z-10">
          <DocumentIcon stroke="#FFFFFF" />
        </div>
      </div>

      <p className="font-medium text-lg text-gray-700">
        Upload your files to Alexi
      </p>
      <p className="text-sm text-gray-500">Maximum 10 files per upload</p>
      <p className="text-sm text-gray-500">Documents up to 2000 pages each</p>
      <p className="text-sm text-gray-500">File size limit is 120 MB each</p>
    </div>
  )
}

export default DragAndDropCard

import React from 'react'

import XIcon from './assets/XIcon'

interface ConfirmDeleteFilesModalProps {
  onCancel: () => void
  onDelete: () => void
}

const ConfirmDeleteFilesModal: React.FC<ConfirmDeleteFilesModalProps> = ({
  onCancel,
  onDelete,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 sm:p-8 min-w-96">
      <div className="bg-white  py-6 rounded-lg shadow-lg w-[500px]">
        <div className="flex px-6 justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-left text-txt-default-default">
            Confirm deletion
          </h2>
          <button onClick={onCancel} className="m-2 w-6 h-6">
            <XIcon classNames="w-[22px] h-[22px]" />
          </button>
        </div>

        <hr className="pt-3 pb-3 w-full" />
        <p className="text-align-left mb-6 px-6  text-md font-normal text-txt-default-default">
          Are you sure you would like to delete all your files? They will be
          permanently deleted.
        </p>
        <div className="flex justify-end space-x-4 px-6 ">
          <button
            onClick={onCancel}
            className="bg-bgCol-neutral-default text-txt-neutral-default px-4 py-2 rounded-lg"
          >
            No, cancel
          </button>
          <button
            onClick={onDelete}
            className="bg-bgCol-danger-default text-txt-danger-danger px-4 py-2 rounded-lg"
          >
            Yes, delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDeleteFilesModal

import axios from 'axios'
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import FormStep from './FormStep'
import { fetchStates } from '../../apis/fetchStatesApi'
import { submitOnboardingForm } from '../../apis/onboardingApi'
import { useStore } from '../../useStore'
import InfoIconSmall from '../assets/InfoIconSmall'
import SelectInput from '../shared/SelectInput'
import Tooltip from '../shared/Tooltip'

interface FormData {
  first_name: string
  last_name: string
  country: string
  country_state: string
  jurisdiction?: string
  role_in_firm: string
  ai_tools: string
}

const AboutUserStep: React.FC = () => {
  const store = useStore()
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      first_name: '',
      last_name: '',
      country: '',
      country_state: '',
      jurisdiction: '',
      role_in_firm: '',
      ai_tools: '',
    },
    mode: 'onChange',
  })

  const country = watch('country')
  const [states, setStates] = React.useState<Record<string, string>>({})
  const [roles, setRoles] = React.useState<Record<string, Array<string>>>({})
  const [roleOptions, setRoleOptions] = React.useState<Record<string, string>>(
    {}
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/client/v1/onboardings/user_info')
        reset(response.data.user)
        setRoles(response.data.role_options)
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 500) {
          window.location.href = '/'
        }
        console.error('Error fetching user data:', error)
      }
    }
    fetchData()
  }, [reset])

  useEffect(() => {
    const loadStates = async () => {
      try {
        const data = await fetchStates(store, country, false)
        const transformedData = data.reduce(
          (acc: Record<string, string>, state: { name: string }) => {
            acc[state.name] = state.name
            return acc
          },
          {}
        )
        setStates(transformedData)
      } catch (error) {
        console.error('Error fetching states:', error)
      }
    }

    if (country) {
      loadStates()
      setRoleOptions(
        roles[country].reduce((acc: Record<string, string>, role: string) => {
          acc[role] = role
          return acc
        }, {})
      )
      reset((formValues) => ({
        ...formValues,
        country_state: '',
      }))
    }
  }, [country, store])

  const onSubmit = async (data: FormData) => {
    await submitOnboardingForm(
      '/api/client/v1/onboardings/complete_user_info',
      data
    )
  }
  const currentStep = store.user?.role === 'member' ? 1 : 2
  const totalSteps = currentStep + 1

  return (
    <FormStep
      step={currentStep}
      totalSteps={totalSteps}
      title="Tell us about you"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {/* Name Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium mb-2"
            >
              First name*
            </label>
            <input
              id="first_name"
              {...register('first_name', {
                required: 'First name is required',
                validate: (value) =>
                  value.trim() !== '' || 'First name cannot be empty',
              })}
              className={`h-10 mt-1 block w-full border border-brd-default-default rounded-md px-4 py-2 focus:ring-txt-brandtext-txt-brand-default focus:border-txt-brandtext-txt-brand-default ${
                errors.first_name
                  ? 'border-brd-danger-default'
                  : 'border-brd-default-default'
              }`}
            />
            {errors.first_name && (
              <p className="mt-1 text-txt-danger-default text-sm">
                {errors.first_name.message}
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium mb-2"
            >
              Last name*
            </label>
            <input
              {...register('last_name', {
                required: 'Last name is required',
                validate: (value) =>
                  value.trim() !== '' || 'Last name cannot be empty',
              })}
              className={`h-10 mt-1 block w-full border border-brd-default-default rounded-md px-4 py-2 focus:ring-txt-brandtext-txt-brand-default focus:border-txt-brandtext-txt-brand-default ${
                errors.last_name
                  ? 'border-brd-danger-default'
                  : 'border-brd-default-default'
              }`}
            />
            {errors.last_name && (
              <p className="mt-1 text-txt-danger-default text-sm">
                {errors.last_name.message}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="country" className="block text-sm font-medium mb-2">
              Country*
            </label>
            <Controller
              name="country"
              control={control}
              rules={{ required: 'Country is required' }}
              render={({ field }) => (
                <SelectInput
                  {...field}
                  options={{
                    US: 'United States',
                    CA: 'Canada',
                  }}
                />
              )}
            />
            {errors.country && (
              <p className="mt-1 text-txt-danger-default text-sm">
                {errors.country.message}
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="country_state"
              className="block text-sm font-medium mb-2"
            >
              {country === 'US' ? 'State*' : 'Province*'}
            </label>
            <Controller
              name="country_state"
              control={control}
              rules={{ required: 'State/Province is required' }}
              render={({ field }) => (
                <SelectInput {...field} options={states} />
              )}
            />
            {errors.country_state && (
              <p className="mt-1 text-txt-danger-default text-sm">
                {errors.country_state.message}
              </p>
            )}
          </div>
        </div>

        {/* Jurisdiction (conditional) */}
        {country === 'US' && (
          <div>
            <label
              htmlFor="jurisdiction"
              className="text-sm font-medium mb-2 flex items-center"
            >
              What is your primary jurisdiction?*
              <Tooltip
                position="above"
                additionalClasses="w-[20rem]"
                tooltipContents="We use this information to set your default jurisdiction to ensure that we provide relevant caselaw and legal information."
              >
                <div className="h-[14px] w-[14px] flex items-center ml-1">
                  <InfoIconSmall strokeColor="#1B212D" />
                </div>
              </Tooltip>
            </label>
            <Controller
              name="jurisdiction"
              control={control}
              rules={{ required: 'Jurisdiction is required' }}
              render={({ field }) => (
                <SelectInput
                  {...field}
                  options={{
                    state_courtes: 'State courts',
                    federal_courtes: 'Federal courts',
                  }}
                  value={field.value ?? ''}
                />
              )}
            />
            {errors.jurisdiction && (
              <p className="mt-1 text-txt-danger-default text-sm">
                {errors.jurisdiction.message}
              </p>
            )}
          </div>
        )}

        {/* Role */}
        <div>
          <label
            htmlFor="role_in_firm"
            className="block text-sm font-medium mb-2"
          >
            How would you describe your role?*
          </label>
          <Controller
            name="role_in_firm"
            control={control}
            rules={{ required: 'Role is required' }}
            render={({ field }) => (
              <SelectInput {...field} options={roleOptions} />
            )}
          />
          {errors.role_in_firm && (
            <p className="mt-1 text-txt-danger-default text-sm">
              {errors.role_in_firm.message}
            </p>
          )}
        </div>

        {/* AI Tools */}
        <div>
          <p className="block text-sm font-medium mb-2">
            Have you used any AI tools before?*
          </p>
          <div className="flex items-center space-x-8">
            {['No, never', 'Yes, a couple of times', 'Yes, quite often'].map(
              (label) => (
                <label
                  key={label}
                  className="inline-flex items-center space-x-2"
                >
                  <input
                    type="radio"
                    value={label}
                    {...register('ai_tools', {
                      required: 'Please select an option',
                    })}
                    className="form-radio text-txt-brand-default focus:ring-txt-text-txt-brand-default"
                  />
                  <span className="text-sm">{label}</span>
                </label>
              )
            )}
          </div>
          {errors.ai_tools && (
            <p className="mt-1 text-txt-danger-default text-sm">
              {errors.ai_tools.message}
            </p>
          )}
        </div>

        {/* Continue Button */}
        <div className="">
          <button
            type="submit"
            disabled={!isValid}
            className="h-11 mt-8 text-center disabled:bg-bgCol-disabled-default disabled:text-txt-disabled-default disabled:cursor-not-allowed w-full flex items-center justify-center py-2 px-4 border border-transparent text-white rounded-md text-sm bg-bgCol-brand-default focus:ring-2 focus:ring-offset-2 cursor-pointer"
          >
            Continue
          </button>
        </div>
      </form>
    </FormStep>
  )
}

export default AboutUserStep

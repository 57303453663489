import { action, runInAction } from 'mobx'

import Store from '../Store'

export const getActivitiesLoading = (store: Store, activityId: string) => {
  return store.activitiesLoading[activityId]
}

interface setActivitiesLoadingProps {
  store: Store
  activityId: string
  durationEstimate: number
  durationStartTime: number
  durationElapsed: number
  intervalId: ReturnType<typeof setInterval> | null
}

export const setActivitiesLoading = action(
  ({
    store,
    activityId,
    durationEstimate,
    durationStartTime,
    durationElapsed,
    intervalId,
  }: setActivitiesLoadingProps) => {
    store.activitiesLoading[activityId] = {
      durationEstimate,
      durationStartTime,
      durationElapsed,
      intervalId,
    }

    saveActivitiesLoadingToLocalStorage(store)
  }
)
export const handleActivityLoadingIntervalUpdate = (
  store: Store,
  activityId: string
) => {
  const activity = store.activitiesLoading[activityId]
  const {
    intervalId: currentInterval,
    durationEstimate,
    durationStartTime,
  } = activity
  const now = Date.now()
  const elapsedSeconds = Math.floor((now - durationStartTime) / 1000)

  runInAction(() => {
    if (elapsedSeconds >= durationEstimate) {
      clearInterval(currentInterval as unknown as number) // Stop the interval
      activity.intervalId = null // Reset the intervalId after loading completed in interval
      activity.durationElapsed = durationEstimate
    } else {
      activity.durationElapsed = elapsedSeconds
    }

    saveActivitiesLoadingToLocalStorage(store) // Persist changes
  })
}

export const startActivityLoading = action(
  (store: Store, activityId: string) => {
    const activity = getActivitiesLoading(store, activityId)
    const {
      intervalId: currentInterval,
      durationEstimate,
      durationElapsed,
    } = activity

    // Clear any existing interval before starting a new one
    if (currentInterval !== null) {
      runInAction(() => {
        clearInterval(currentInterval as unknown as number)
        activity.intervalId = null
      })
    }

    // create and set the new intervalId in the store if the loading is not completed
    if (durationElapsed < durationEstimate) {
      const newIntervalId = setInterval(() => {
        handleActivityLoadingIntervalUpdate(store, activityId)
      }, 1000)

      runInAction(() => {
        store.activitiesLoading[activityId].intervalId = newIntervalId
      })
    }
  }
)

const saveActivitiesLoadingToLocalStorage = (store: Store) => {
  localStorage.setItem(
    'activitiesLoading',
    JSON.stringify(store.activitiesLoading)
  )
}

import React, { useState } from 'react'

import DownloadIcon from '../../assets/DownloadIcon'
import Tooltip from '../../shared/Tooltip'
import { IInstantMemo } from '../../types/interfaces'

function MemoDownloadDropdown(props: {
  instantMemo: IInstantMemo
  forDocumentsSection: boolean
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const pdfUrl = `/api/client/v1/instant_memos/${props.instantMemo.id}/pdf`
  const docxUrl = `/api/client/v1/instant_memos/${props.instantMemo.id}/docx`

  return (
    <div className="relative inline-block">
      <Tooltip tooltipContents="Download" position="left">
        <button
          className={`inline-block ${props.forDocumentsSection ? '' : 'm-2'} w-6 h-6`}
          onClick={(e) => {
            e.stopPropagation()
            setIsMenuOpen(!isMenuOpen)
          }}
        >
          <DownloadIcon
            classNames={`${props.forDocumentsSection ? '' : 'w-6 h-6'}`}
          />
        </button>
      </Tooltip>

      {isMenuOpen && (
        <div
          className={`group absolute right-0 mt-2 bg-white rounded-lg shadow-md z-50 text-black text-sm
          ${props.forDocumentsSection ? 'w-40 top-5 p-2' : 'w-48 top-6'}`}
          onClick={(e) => e.stopPropagation()} // Prevent closing on click inside
          onMouseLeave={() => setIsMenuOpen(false)}
        >
          <ul className="p-1">
            {props.instantMemo.memo_version !== '0.0.8' && (
              <li className="block px-2 py-2 pl-4 cursor-pointer hover:bg-gray-50 rounded text-left">
                <a href={docxUrl} target="_blank" rel="noopener noreferrer">
                  {props.forDocumentsSection
                    ? 'Microsoft Word'
                    : 'Microsoft word (.docx)'}
                </a>
              </li>
            )}
            <li className="block px-2 py-2 pl-4 cursor-pointer hover:bg-gray-50 rounded text-left">
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                {props.forDocumentsSection
                  ? 'PDF Document'
                  : 'PDF document (.pdf)'}
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default MemoDownloadDropdown

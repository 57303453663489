import React from 'react'

import ClearButton from './ClearButton'
import InfoIcon from '../../assets/InfoIcon'
import Tooltip from '../../shared/Tooltip'

interface BackgroundFactsFormProps {
  inputValue: string
  setInputValue: (value: string) => void
}

const BackgroundFactsForm: React.FC<BackgroundFactsFormProps> = ({
  inputValue,
  setInputValue,
}) => (
  <div className="my-4 py-2">
    <label className="text-base font-medium text-txt-default-default mb-2 flex items-center">
      Background facts (optional)
      <Tooltip
        tooltipContents="Facts help contextualize the research. We'll check for similar fact cases."
        position="above"
        additionalClasses="w-[16rem] fit-content"
      >
        <span className="ml-2 inline-flex items-center">
          <InfoIcon />
        </span>
      </Tooltip>
    </label>

    <div className="relative">
      <div className="absolute right-0 -top-8 mb-2">
        <ClearButton
          isEnabled={inputValue.trim().length > 0}
          onClick={() => setInputValue('')}
        />
      </div>
      <textarea
        className="w-full h-16 p-2 border rounded-lg box-border"
        placeholder="E.g., On appeal following conviction, my client seeks to introduce newly discovered emails showing he flagged accounting anomalies"
        rows={4}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  </div>
)

export default BackgroundFactsForm

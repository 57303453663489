import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import Loading from './assets/Loading'
import ChatInput from './ChatInput'
import DragAndDropCard from './file_uploads/DragAndDropCard'
import { handleFilesUpload } from './file_uploads/HandleFilesUpload'
import ChatMessage from './main_chat/ChatMessage'
import ChatMessageSkeleton from './main_chat/ChatMessageSkeleton'
import LatestMessageButton from './main_chat/LatestMessageButton'
import SelectedChatHeader from './main_chat/SelectedChatHeader'
import { getChatResponseLoading } from '../actions/chatResponseLoadingActions'
import { setSelectedMatter } from '../actions/matterActions'
import { getMessages, setMessages } from '../actions/messagesActions'
import { fetchChatHistory } from '../apis/chatApi'
import { useStore } from '../useStore'

const LegacyMainChat = observer(() => {
  const store = useStore()
  const navigate = useNavigate()

  const chatContainerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [dragActive, setDragActive] = useState(false)

  const { id: matterId } = useParams()

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'instant',
      })
    }
  }

  const checkIfNewMessagesBelow = () => {
    const chatContainer = chatContainerRef.current
    if (!chatContainer) {
      return
    }

    const { scrollTop, scrollHeight, clientHeight } = chatContainer
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight)

    // Show the latest messages button only if the distance from the bottom is greater than 200px
    store.showLatestMessagesButton = distanceFromBottom > 200
  }

  const loadChatHistory = async () => {
    try {
      const messages = await fetchChatHistory(store, {
        chat_thread_message: {
          matter_id: matterId,
          thread_id: store.selectedMatter.thread_id,
        },
      })

      setMessages(store, matterId, messages)
    } catch (error) {
      console.error('Error fetching chat history:', error)
    }
  }

  useEffect(() => {
    const matter = store.matters.find((m) => m.id === matterId)
    if (matter && store.selectedMatter?.id !== matter.id) {
      setSelectedMatter(store, matter)
    }

    loadChatHistory()
    //   eslint-disable-next-line react-hooks/exhaustive-deps -- this is correct
  }, [matterId])

  const matterMessages = getMessages(store, matterId)
  const lastMessageLength = matterMessages?.at(-1)?.content?.length
  const matterMessageStoreLength = matterMessages?.length

  useEffect(() => {
    scrollToBottom()

    if (chatContainerRef.current) {
      setContainerWidth(chatContainerRef.current.offsetWidth)
    }
  }, [matterMessageStoreLength, lastMessageLength])

  const filteredFiles = store.uploadedFiles.filter(
    (file) => file.matterId === matterId
  )

  const handleDragOver = (e) => {
    e.preventDefault()
    setDragActive(true)
  }

  const handleDragLeave = () => {
    setDragActive(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    setDragActive(false)
    const files = Array.from(e.dataTransfer.files)
    handleFilesUpload(store, filteredFiles.length, files, navigate)
  }

  return (
    <div
      className="flex-1 flex flex-col relative"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {store.selectedMatter && (
        <SelectedChatHeader
          selectedMatter={store.selectedMatter}
          user={store.user}
        />
      )}

      <div
        className="flex-1 pt-2 overflow-y-auto chat-container"
        ref={chatContainerRef}
        onScroll={checkIfNewMessagesBelow}
      >
        {matterMessages && matterMessages.length === 0 ? (
          <Loading />
        ) : (
          matterMessages?.map((message) => (
            <ChatMessage
              key={message.id}
              message={message}
              mainChatContainerWidth={containerWidth}
            />
          ))
        )}
        {getChatResponseLoading(store, matterId) && <ChatMessageSkeleton />}
      </div>
      <div className="bg-white p-7 pb-2">
        {store.showLatestMessagesButton && (
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-10">
            <LatestMessageButton
              store={store}
              scrollToBottom={scrollToBottom}
            />
          </div>
        )}
        <ChatInput />
        <div className="mt-2 text-center text-xs text-txt-neutral-secondary">
          Alexi is a conversational tool designed to assist and provide
          information to the best of its abilities. Consider verifying important
          information.
        </div>
      </div>

      {dragActive && <DragAndDropCard />}
    </div>
  )
})

export default LegacyMainChat

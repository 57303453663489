import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'

import ChatApp from './ChatApp'
import HomeScreen from './HomeScreen'
import ChatTemplate from './layouts/chat_layout/ChatTemplate'
import ChatLayout from './layouts/ChatLayout'
import LegacyMainChat from './LegacyMainChat'
import OnboardingFlow from './OnboardingFlow'
import MatterWorkflow from './workflow/MatterWorkflow'

function App() {
  const { podCia } = useFlags<{ [key: string]: boolean }>()

  return (
    <Router>
      <Routes>
        <Route path="/onboarding/*" element={<OnboardingFlow />} />
        <Route path="/onboarding" element={<OnboardingFlow />} />
        {podCia ? (
          <Route path="/chat" element={<ChatLayout />}>
            {/*TODO: maybe we should have initialChatTemplate when no selected matter (id: null) */}
            <Route index element={<ChatTemplate />} />
            <Route path=":id" element={<ChatTemplate />} />
          </Route>
        ) : (
          <Route path="/chat" element={<ChatApp />}>
            <Route index element={<HomeScreen />} />
            <Route
              path=":id"
              element={
                <>
                  <LegacyMainChat />
                  <MatterWorkflow />
                </>
              }
            />
          </Route>
        )}
        <Route path="*" element={<Navigate to="/chat" />} />
      </Routes>
    </Router>
  )
}

export default App

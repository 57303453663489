import PropTypes from 'prop-types'
import React from 'react'

const FilterIcon = ({ stroke = '#1B212D', className = '' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M1 4H19M5 10H15M8 16H12"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

FilterIcon.propTypes = {
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export default FilterIcon

import React from 'react'

import { completeStep } from '../../apis/onboardingApi'
import assetPath from '../../assetPath'
import { useStore } from '../../useStore'
import FileMagicIcon from '../assets/FileMagicIcon'
import QuestionIcon from '../assets/QuestionIcon'
import ShieldIcon from '../assets/ShieldIcon'
import TrialImage from '../assets/TrialImage'

const TrialInfo: React.FC = () => {
  const store = useStore()

  return (
    <div className="flex flex-row justify-around w-full max-w-7xl mx-auto lg:px-8 h-full space-y-8 lg:space-y-0 lg:space-x-12 items-center text-txt-default-default">
      {/* Left Section */}
      <div className="flex-1 sm:min-w-[45rem] bg-white rounded-lg p-4 sm:p-6 lg:p-10">
        {/* Header Section */}
        <div className="mb-6">
          <p className="px-3 py-1 text-sm font-medium text-txt-brand-default border border-brd-brand-default rounded-full bg-bgCol-brand-secondary inline-block">
            No credit card required
          </p>
          <h1 className="text-3xl font-bold mt-4">
            Your 7 day trial journey starts today 🎉
          </h1>
          <p className="text-base mt-4">
            Accelerate productivity and go further for your clients with Alexi.
          </p>
        </div>

        <div>
          <p className="text-base font-medium mb-4">
            With Alexi, you will get:
          </p>
          <div className="space-y-4">
            <div className="flex items-center">
              <div className="p-2 bg-bgCol-brand-secondary rounded-md mr-4">
                <QuestionIcon />
              </div>
              <p className="text-base">
                Reliable and quick answers to your legal questions.
              </p>
            </div>
            <div className="flex items-center">
              <div className="p-2 bg-bgCol-brand-secondary rounded-md mr-4">
                <FileMagicIcon />
              </div>
              <p className="text-base">Full legal research memos in minutes.</p>
            </div>
          </div>
        </div>

        <p className="text-base font-medium mt-8">
          Your information is 100% private, secure & confidential.
        </p>
        <div className="flex items-center mt-4">
          <div className="p-2 bg-bgCol-brand-secondary rounded-md mr-4">
            <ShieldIcon />
          </div>
          <p className="text-base">
            User data in Alexi is protected by trusted security measures and
            will never be shared with third parties.
          </p>
        </div>

        <div className="border border-brd-brand-tertiary p-2 sm:p-4 rounded-md mt-6 flex items-center space-x-4">
          <div className="flex -space-x-2 mr-[10px] sm:mr-0">
            <img
              src={assetPath(store, 'trent.png')}
              alt="Trent - Product Specialist"
              className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 border-white"
            />
            <img
              src={assetPath(store, 'daniel.png')}
              alt="Daniel - Product Specialist"
              className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 border-white"
            />
          </div>
          <div>
            <p className="font-medium">Want to talk to a human?</p>
            <a
              href="https://calendly.com/trenten-alexi"
              target="_blank"
              rel="noreferrer"
              className="text-txt-brand-default hover:underline"
            >
              Book a walk-through of Alexi with an onboarding specialist
              <span aria-hidden="true" className="ml-1">
                ↗
              </span>
            </a>
          </div>
        </div>

        {/* Continue Button */}
        <button
          className="mt-6 w-full py-3 px-4 bg-bgCol-brand-default text-white rounded-md font-medium hover:bg-indigo-600"
          onClick={() => completeStep('trial_info')}
        >
          Continue
        </button>
      </div>

      {/* Right Section */}
      <div className="hidden  lg:flex flex-1 justify-center lg:justify-end items-center">
        <div className="w-full max-w-sm sm:max-w-md lg:max-w-[52rem] h-[48rem] pt-[4.25rem]">
          <TrialImage />
        </div>
      </div>
    </div>
  )
}

export default TrialInfo

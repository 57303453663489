import { Route, Routes, Outlet } from 'react-router-dom'

import FlashMessages from './FlashMessages'
import AboutLawFirmStep from './onboarding/AboutLawFirmStep'
import AboutUserStep from './onboarding/AboutUserStep'
import TrialInfo from './onboarding/TrialInfo'
import assetPath from '../assetPath'
import StoreContext from '../StoreContext'
import { useStore } from '../useStore'

const OnboardingFlow: React.FC = () => {
  const store = useStore()

  return (
    <StoreContext.Provider value={store}>
      <header className="flex justify-start p-6 mb-5">
        <img
          src={assetPath(store, 'alexiTitleLogo.svg')}
          alt="Alexi Logo"
          className="h-8 w-auto"
        />
      </header>
      <div className="flex flex-col h-full items-center overflow-hidden min-w-full max-w-full lg:min-w-[1024px] lg:max-w-[1920px] min-h-[720px] mx-auto">
        <Routes>
          <Route path="firm_info" element={<AboutLawFirmStep />} />
          <Route path="user_info" element={<AboutUserStep />} />
          <Route path="trial_info" element={<TrialInfo />} />
          <Route path="*" element={<Outlet />} />
        </Routes>
        <FlashMessages />
      </div>
    </StoreContext.Provider>
  )
}

export default OnboardingFlow

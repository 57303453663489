import {
  Button,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import React, { Fragment, useState } from 'react'

import { Matter } from '../../Store'
import ThreeDotsIcon from '../assets/ThreeDotsIcon'
import DeleteMatterModal from '../DeleteMatterModal'
import SettingsModal from '../SettingsModal'

export interface MattersListItemProps {
  matter: Matter
  isSelected: boolean
  onMatterClick: (matter: Matter) => void
  onDeleteClick: (matter: Matter) => void
}

const MattersListItem = ({
  matter,
  isSelected,
  onMatterClick,
  onDeleteClick,
}: MattersListItemProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isDeleteMatterModalOpen, setIsDeleteMatterModalOpen] = useState(false)

  const handleSettingsClick = (e: any) => {
    e.stopPropagation()
    setIsSettingsOpen(true)
    setIsMenuOpen(false)
  }

  const handleDeleteClick = async (e: any) => {
    e.stopPropagation()
    setIsMenuOpen(false)
    setIsDeleteMatterModalOpen(true)
  }

  const handleMatterClick = () => {
    onMatterClick(matter)
  }

  return (
    <Fragment>
      <ListItem
        key={matter.id}
        selected={isSelected}
        onClick={handleMatterClick}
        className="h-11 p-2 pr-0 group cursor-pointer bg-white focus:bg-bgCol-brand-secondary focus:text-txt-brand-default hover:bg-bgCol-brand-secondary hover:text-txt-brand-default"
        onMouseLeave={() => setIsMenuOpen(false)}
      >
        <div className="flex items-center justify-between w-full space-x-2">
          <div className="flex flex-col whitespace-nowrap overflow-hidden">
            <span className="truncate text-sm block">{matter.title}</span>
            {matter.file_number && (
              <p
                className={`text-xs ${isSelected ? 'text-txt-brand-secondary' : 'text-txt-neutral-secondary'} group-hover:text-txt-brand-secondary`}
              >
                {matter.file_number}
              </p>
            )}
          </div>
          <div>
            <Menu
              open={isMenuOpen}
              handler={setIsMenuOpen}
              placement="bottom-end"
            >
              <MenuHandler>
                <Button className="bg-transparent p-1 pr-2 border-0 opacity-0 group-hover:opacity-100 focus-visible:outline-none transition-opacity duration-150 z-50">
                  <ThreeDotsIcon />
                </Button>
              </MenuHandler>
              <MenuList className="p-0 min-w-max text-black text-sm shadow-md border-0 z-[9999]">
                <MenuItem onClick={handleSettingsClick}>View details</MenuItem>
                <MenuItem
                  className="text-txt-danger-default hover:text-txt-danger-default focus:text-txt-danger-default"
                  onClick={handleDeleteClick}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </ListItem>
      {isSettingsOpen && (
        <SettingsModal
          matter={matter}
          onClose={() => setIsSettingsOpen(false)}
        />
      )}
      {isDeleteMatterModalOpen && (
        <DeleteMatterModal
          matter={matter}
          onClose={() => setIsDeleteMatterModalOpen(false)}
          onDelete={onDeleteClick}
        />
      )}
    </Fragment>
  )
}

export default MattersListItem

import { Drawer } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'

import { isMdSize, setShowSidebar } from '../../../actions/showLayoutActions'
import assetPath from '../../../assetPath'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { useStore } from '../../../useStore'
import MattersList from '../../matter/MattersList'
import MattersListHeader from '../../matter/MattersListHeader'
import CollapsibleLayout from '../../shared/CollapsibleLayout'
import UserProfileAndSettingFooter from '../../shared/UserProfileAndSettingFooter'

export const Sidebar = observer(() => {
  const store = useStore()
  const { showSidebar } = store.showLayouts
  const { screenWidth } = useScreenSize()

  const content = (
    <CollapsibleLayout
      className={`border-0 border-r border-brand-tertiary transition-all ${showSidebar ? 'md:w-60 xl:w-80' : 'w-0'}`}
      navBarClassName="h-[77px] border-0 border-b border-brand-tertiary"
      headerLeftComponent={
        <img src={assetPath(store, 'alexiTitleLogo.svg')} alt="Image" />
      }
      showRightControlButton={true}
      handleRightControlButtonClick={() =>
        setShowSidebar(store, !store.showLayouts.showSidebar)
      }
    >
      <div className="flex flex-col w-full h-screen">
        <MattersListHeader />
        <div className="overflow-y-auto flex-grow px-2">
          <MattersList />
        </div>
        <div className="sticky bottom-0 items-center">
          <UserProfileAndSettingFooter />
        </div>
      </div>
    </CollapsibleLayout>
  )
  return (
    <Fragment>
      {isMdSize(screenWidth) && showSidebar ? (
        content
      ) : (
        <Drawer
          open={showSidebar}
          onClose={() => {
            setShowSidebar(store, false)
          }}
        >
          {content}
        </Drawer>
      )}
    </Fragment>
  )
})

export default Sidebar

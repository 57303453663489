import React, { useState } from 'react'

import DocumentDeleteForm from './DocumentDeleteForm'
import { downloadFile } from '../../apis/filesApi'
import { useStore } from '../../useStore'
import DownloadIcon from '../assets/DownloadIcon'
import TrashIcon from '../assets/TrashIcon'
import Tooltip from '../shared/Tooltip'
import { IEnterpriseDoc } from '../types/interfaces'

interface DocumentProps {
  doc: IEnterpriseDoc
}

const EnterpriseDocument: React.FC<DocumentProps> = ({ doc }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const store = useStore()

  return (
    <tr key={doc.id}>
      {/* File Name */}
      <td className="p-2">{doc.file_name}</td>

      {/* Document Type */}
      {/*<td className="p-2">{doc.content_type}</td>*/}

      {/*/!* Description *!/*/}
      {/*<td className="p-2">{doc.generated_name}</td>*/}

      {/* Modified Time */}
      <td className="p-2 flex items-center space-x-2">
        <Tooltip tooltipContents="Download" position="top">
          <button
            onClick={() => downloadFile(store, doc)}
            className="text-blue-600 hover:underline"
          >
            <DownloadIcon />
          </button>
        </Tooltip>
        <Tooltip tooltipContents="Delete" position="top">
          <button
            onClick={() => setIsDeleteModalOpen(true)}
            className="text-red-600 hover:underline"
          >
            <TrashIcon />
          </button>
        </Tooltip>
      </td>

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <DocumentDeleteForm
          doc={doc}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </tr>
  )
}

export default EnterpriseDocument

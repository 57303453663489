import axios from 'axios'

// Set withCredentials to true for all requests
axios.defaults.withCredentials = true

export const completeStep = async (step) => {
  const csrfTokenElement = document.querySelector('meta[name="csrf-token"]')
  const csrfToken = csrfTokenElement
    ? csrfTokenElement.getAttribute('content')
    : ''

  try {
    await axios.post(
      `/api/client/v1/onboardings/complete_${step}`,
      {},
      {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }
    )
    window.location.href = '/chat'
  } catch (error) {
    console.error('Error completing onboarding:', error)
  }
}

export const submitOnboardingForm = async (endpoint, data) => {
  const csrfTokenElement = document.querySelector('meta[name="csrf-token"]')
  const csrfToken = csrfTokenElement?.getAttribute('content') || ''

  try {
    const response = await axios.post(
      endpoint,
      { onboarding: data },
      {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }
    )
    if (response.data.redirect_url) {
      window.location.href = response.data.redirect_url
    }
  } catch (error) {
    console.error('Error submitting onboarding form:', error)
  }
}

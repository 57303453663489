import { observer } from 'mobx-react-lite'
import React from 'react'

import DocumentManager from './DocumentManager'
import MainChat from './MainChat'
import { isMdSize } from '../../../actions/showLayoutActions'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { useStore } from '../../../useStore'

const ChatTemplate = observer(() => {
  const store = useStore()
  const { screenWidth } = useScreenSize()
  const { showDocumentManager } = store.showLayouts

  if (!isMdSize(screenWidth)) {
    return !showDocumentManager ? <MainChat /> : <DocumentManager />
  }

  return (
    <div
      className={`grid ${showDocumentManager ? `grid-cols-2` : 'grid-cols'}`}
    >
      <MainChat />
      {showDocumentManager ? <DocumentManager /> : null}
    </div>
  )
})

export default ChatTemplate

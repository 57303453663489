import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import FilesAndFolders from './FilesAndFolders'
import { setFetchedFilesAndFolders } from '../../../actions/fileActions'
import { setShowDocumentManagers } from '../../../actions/showLayoutActions'
import { useStore } from '../../../useStore'
import Loading from '../../assets/Loading'
import CollapsibleLayout from '../../shared/CollapsibleLayout'

export const DocumentManager = observer(() => {
  const store = useStore()
  const currentFolder = store.currentFolder
  const [foldersLoading, setFoldersLoading] = React.useState(true)

  useEffect(() => {
    setFoldersLoading(true)

    setFetchedFilesAndFolders(store).then(() => {
      setFoldersLoading(false)
    })
  }, [])

  if (foldersLoading) {
    return <Loading />
  }

  return (
    <CollapsibleLayout
      className="border-0 border-l border-brand-tertiary h-full"
      headerLeftComponent={
        <span className="text-lg font-semibold text-txt-default-default">
          File Manager
        </span>
      }
      showCloseButton={true}
      handleCloseButtonClick={() => setShowDocumentManagers(store, false)}
    >
      <FilesAndFolders currentFolder={currentFolder} />
    </CollapsibleLayout>
  )
})

export default DocumentManager

import PropTypes from 'prop-types'
import React, { useState } from 'react'

const SelectInput = ({ value, onChange, options }) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div
      className={`border rounded-md py-1 ${
        isFocused
          ? 'ring-2 outline-none ring-brd-brand-default border-brd-brand-default'
          : 'border-brd-default-default'
      }`}
    >
      <select
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`block w-full py-1 border-r-8 border-transparent px-3 text-sm focus:outline-none ${
          value ? 'text-txt-default-default' : 'text-txt-default-tertiary'
        }`}
      >
        <option className="text-txt-default-tertiary" value="" disabled hidden>
          Choose an option
        </option>
        {Object.entries(options).map(([key, optionLabel]) => (
          <option key={key} value={key}>
            {optionLabel}
          </option>
        ))}
      </select>
    </div>
  )
}

SelectInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
}

export default SelectInput

// ldProvider.js

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

import Store from './Store'

export async function initializeLDProvider(store: Store) {
  if (!store.user) {
    throw new Error('User info is required to initialize LaunchDarkly')
  }

  return await asyncWithLDProvider({
    clientSideID: store.credentials.launchDarklyClientId ?? '',
    context: {
      kind: 'multi',
      user: {
        key: store.user.id,
        name: store.user.full_name,
        email: store.user.email,
      },
      company: {
        key: store.user.enterprise.id,
        name: store.user.enterprise.name,
      },
    },
  })
}

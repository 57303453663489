import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import React, { Fragment } from 'react'

import { isMdSize } from '../../actions/showLayoutActions'
import { useScreenSize } from '../../hooks/useScreenSize'
import { useStore } from '../../useStore'
import BellIcon from '../assets/BellIcon'
import FingerprintIcon from '../assets/FingerprintIcon'
import GearIcon from '../assets/GearIcon'
import HomeIcon from '../assets/HomeIcon'
import SignOutIcon from '../assets/SignOutIcon'

const UserProfileAndSettingFooter = () => {
  const { screenWidth } = useScreenSize()
  const store = useStore()

  const [showDropdown, setShowDropdown] = React.useState(false)

  return (
    <Fragment>
      {store.user && (
        <div
          className="p-2 group cursor-pointer"
          onMouseLeave={() => {
            if (!isMdSize(screenWidth)) {
              setShowDropdown(false)
            }
          }}
        >
          <Menu
            open={showDropdown}
            handler={setShowDropdown}
            placement={!isMdSize(screenWidth) ? 'top-end' : 'right-end'}
          >
            <MenuHandler>
              <Button
                className={`w-full px-2 h-11 focus-visible:outline-none bg-bgCol-default-default hover:bg-bgCol-brand-secondary ${showDropdown ? 'bg-bgCol-brand-secondary' : ''}`}
              >
                <div className="flex items-center gap-2">
                  <div className="flex justify-center items-center uppercase bg-bgCol-brand-default rounded-full w-6 h-6">
                    <span className="text-xs font-semibold text-white">
                      {store.user.initials}
                    </span>
                  </div>
                  <span
                    className={`text-sm font-normal text-black  hover:text-txt-brand-default ${showDropdown ? 'text-txt-brand-default' : 'text-black'}`}
                  >
                    {store.user.full_name}
                  </span>
                </div>
              </Button>
            </MenuHandler>
            <MenuList className="p-1 min-w-max text-black text-sm shadow-md border border-bgCol-neutral-secondary z-[9999] ">
              <MenuItem className="focus:hover:bg-bgCol-default-defaultHover">
                <a
                  href={`${store.credentials.customerAppUrl}/users/my_profile`}
                >
                  <li className="flex items-center rounded-md whitespace-nowrap">
                    <span className="mr-2">
                      <HomeIcon />
                    </span>
                    Account Details
                  </li>
                </a>
              </MenuItem>

              <MenuItem className="focus:hover:bg-bgCol-default-defaultHover">
                <a
                  href={`${store.credentials.customerAppUrl}/users/security/edit`}
                >
                  <li className="flex items-center rounded-md whitespace-nowrap">
                    <span className="mr-2">
                      <FingerprintIcon />
                    </span>
                    Login & Security
                  </li>
                </a>
              </MenuItem>

              <MenuItem className="focus:hover:bg-bgCol-default-defaultHover">
                <a
                  href={`${store.credentials.customerAppUrl}/users/profiles/edit_notification_prefs`}
                >
                  <li className="flex items-center rounded-md whitespace-nowrap">
                    <span className="mr-2">
                      <BellIcon />
                    </span>
                    Notifications
                  </li>
                </a>
              </MenuItem>
              <hr className="border-1 border-bgCol-neutral-secondary p-0" />
              <MenuItem className="focus:hover:bg-bgCol-default-defaultHover">
                <a href={`${store.credentials.customerAppUrl}/users/sign_out`}>
                  <li className="flex items-center rounded-md whitespace-nowrap">
                    <span className="mr-2">
                      <SignOutIcon />
                    </span>
                    Sign Out
                  </li>
                </a>
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      )}
      {store.user?.admin && (
        <div className="flex h-14 bg-white px-4 py-2 border-t border-brand-tertiary items-center">
          <a href={`${store.credentials.customerAppUrl}/firms/admin/account`}>
            <div className="flex items-center gap-2">
              <GearIcon />
              <span className="text-sm">Admin Setting</span>
            </div>
          </a>
        </div>
      )}
    </Fragment>
  )
}

export default UserProfileAndSettingFooter

import PropTypes from 'prop-types'
import React from 'react'

import { useStore } from '../../../useStore'

const MemoFormSubmitted = ({ responseAdditionalContext }) => {
  const store = useStore()
  const normalizeValue = (value) => {
    return value === 'none' || value === undefined || value === 'null'
      ? ''
      : value
  }

  const renderField = (label, content) => {
    const normalizedContent = normalizeValue(content)
    return normalizedContent ? (
      <div className="mb-6">
        <p className="text-txt-default-default font-medium text-lg mb-2">
          {label}
        </p>
        <p
          className="text-txt-default-default font-normal text-base mt-1"
          dangerouslySetInnerHTML={{ __html: normalizedContent }}
        />
      </div>
    ) : null
  }

  const renderJurisdictionRow = () => {
    const jurisdiction = normalizeValue(responseAdditionalContext.jurisdiction)
    const state = normalizeValue(responseAdditionalContext.state)

    // Determine what to render based on conditions
    if (store.user.enterprise.country_code === 'CA') {
      // For Canada, only render the state
      return (
        <div className="mb-6 flex flex-wrap items-start gap-4">
          {state && (
            <div className="flex-1">
              <p className="text-txt-default-default font-medium text-lg mb-2">
                State
              </p>
              <p
                className="text-txt-default-default font-normal text-base mt-1"
                dangerouslySetInnerHTML={{ __html: state }}
              />
            </div>
          )}
        </div>
      )
    } else {
      // For US, always render jurisdiction
      return (
        <div className="mb-6 flex flex-wrap items-start gap-4">
          {jurisdiction && (
            <div className="flex-1">
              <p className="text-txt-default-default font-medium text-lg mb-2">
                Jurisdiction
              </p>
              <p
                className="text-txt-default-default font-normal text-base mt-1"
                dangerouslySetInnerHTML={{ __html: jurisdiction }}
              />
            </div>
          )}
          {state && (
            <div className="flex-1">
              <p className="text-txt-default-default font-medium text-lg mb-2">
                {jurisdiction === 'Federal' ? 'Forum' : 'State'}
              </p>
              <p
                className="text-txt-default-default font-normal text-base mt-1"
                dangerouslySetInnerHTML={{ __html: state }}
              />
            </div>
          )}
        </div>
      )
    }
  }

  return (
    <div className="rounded-lg">
      {/* Research Question Section */}
      {renderField(
        'Research question',
        responseAdditionalContext.research_question
      )}

      {/* Research Facts Section */}
      {renderField(
        'Background facts',
        responseAdditionalContext.research_facts
      )}

      {/* Jurisdiction, State, and Forum Section */}
      {renderJurisdictionRow()}
    </div>
  )
}

MemoFormSubmitted.propTypes = {
  responseAdditionalContext: PropTypes.shape({
    research_question: PropTypes.string.isRequired,
    research_facts: PropTypes.string,
    jurisdiction: PropTypes.string,
    state: PropTypes.string,
    forum: PropTypes.string,
  }).isRequired,
}

export default MemoFormSubmitted

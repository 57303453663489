import React from 'react'

const UploadSection = () => {
  return (
    <div className="mt-6 p-6 border-[1.5px] border-dashed border-brd-default-default rounded-lg bg-bgCol-neutral-default h-[369px] flex flex-col justify-center items-center gap-6">
      <p className="text-center text-gray-600 text-md font-medium">
        Drag & Drop or upload folders and files.
        <br />
        <span className="text-sm text-center font-normal">
          Upload folders, .csv, .doc, .docx, .jpeg, .jpg, .pdf, .png, .ppt,
          .pptx, .xls, .xlsx files.
        </span>
      </p>
      <div className="flex justify-center">
        <button className="px-6 py-2 bg-bgCol-brand-default text-white rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-400">
          Upload
        </button>
      </div>
    </div>
  )
}

export default UploadSection

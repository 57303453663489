import axios from 'axios'

import { extendSessionTimeout } from '../actions/sessionActions'

export const fetchStates = async (
  store,
  country_code,
  include_federal = true
) => {
  extendSessionTimeout(store)

  try {
    const response = await axios.get(
      `/api/client/v1/states?country_code=${country_code}&include_federal=${include_federal}`
    )
    return response.data // Ensure that this returns the correct data structure
  } catch (error) {
    console.error('Error fetching states:', error)
    throw error
  }
}

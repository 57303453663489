import { action, runInAction } from 'mobx'
import React from 'react'

import { fetchFiles, fetchFilesAndFolders } from '../apis/filesApi'
import { rollbarConfig } from '../rollbarConfig'

const removeFile = action((store, blobId) => {
  store.uploadedFiles = store.uploadedFiles.filter(
    (attachment) => attachment.file.id !== blobId
  )
})

const setFetchedDocuments = action(async (store) => {
  try {
    const documents = await fetchFiles(store)

    if (documents.length === 0) {
      runInAction(() => {
        store.documents = [] // reset document store
      })
      return
    }

    runInAction(() => {
      store.documents = documents.map((document) => ({
        ...document,
        ref: React.createRef(),
      }))
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

const setFetchedFilesAndFolders = action(async (store) => {
  try {
    const folders = await fetchFilesAndFolders(store)

    if (folders.length === 0) {
      runInAction(() => {
        store.folders = {} // reset document store
      })
      return
    }

    runInAction(() => {
      store.folders = folders
      store.currentFolder = folders
    })
  } catch (error) {
    rollbarConfig(store)?.error(error)
  }
})

export { removeFile, setFetchedDocuments, setFetchedFilesAndFolders }

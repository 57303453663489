import { Navbar, IconButton } from '@material-tailwind/react'
import React from 'react'

import ArrowLeftToLine from '../assets/ArrowLeftToLine'
import ArrowRightToLine from '../assets/ArrowRightToLeft'
import CloseIcon from '../assets/CloseIcon'

export interface CollapsibleLayoutProps {
  className?: string
  navBarClassName?: string
  headerLeftComponent?: any
  headerRightComponent?: any
  showLeftControlButton?: boolean
  showRightControlButton?: boolean
  showCloseButton?: boolean
  handleLeftControlButtonClick?: () => void
  handleRightControlButtonClick?: () => void
  handleCloseButtonClick?: () => void
  children?: any
}

const CollapsibleLayout = (props: CollapsibleLayoutProps) => {
  const {
    className = '',
    navBarClassName = '',
    headerLeftComponent,
    headerRightComponent,
    showLeftControlButton = false,
    showRightControlButton = false,
    showCloseButton = false,
    handleLeftControlButtonClick,
    handleRightControlButtonClick,
    handleCloseButtonClick,
    children,
  } = props

  return (
    <div className={`h-screen overflow-y-auto ${className}`}>
      <Navbar
        className={`sticky top-0 z-10 p-4 max-w-full rounded-none ${navBarClassName}`}
      >
        <div className="container max-w-full flex items-center justify-between">
          <div className="flex items-center gap-2">
            {showLeftControlButton && (
              <IconButton
                className="bg-bgCol-default-default"
                onClick={handleLeftControlButtonClick}
              >
                <ArrowRightToLine />
              </IconButton>
            )}
            <div>{headerLeftComponent}</div>
          </div>
          <div className="flex items-center gap-2">
            {headerRightComponent}
            {showRightControlButton && (
              <IconButton
                className="bg-bgCol-default-default"
                onClick={handleRightControlButtonClick}
              >
                <ArrowLeftToLine />
              </IconButton>
            )}
            {showCloseButton && (
              <IconButton
                className="bg-bgCol-default-default"
                onClick={handleCloseButtonClick}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
      </Navbar>
      <div className="container max-w-full flex">{children}</div>
    </div>
  )
}

export default CollapsibleLayout

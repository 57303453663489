import PropTypes from 'prop-types'
import React from 'react'

import XIcon from './assets/XIcon'

const handleDeleteMatter = (matter, onClose, onDelete) => {
  onDelete(matter)
  onClose()
}

const handleCancelDeleteMatter = (onClose) => {
  onClose()
}

const DeleteMatterModal = ({ matter, onDelete, onClose }) => {
  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
        <div className="bg-white py-6 rounded-lg shadow-lg max-w-lg w-full text-txt-default-default">
          <div className="px-6 pb-4 flex justify-between items-center border-b border-brd-default-defaul">
            <h2 className="text-lg font-semibold">Delete Matter</h2>
            <div onClick={onClose}>
              <XIcon />
            </div>
          </div>
          <div className="py-4 px-6">
            <label className="block mb-4">
              Are you sure you would like to delete{' '}
              <span className={'font-medium md:italic break-words'}>
                {' '}
                {matter.title}{' '}
              </span>
              ?
            </label>

            <label>All existing data will be permanently erased.</label>
          </div>

          <div className="px-4 text-right">
            <button
              className="bg-bgCol-disabled-default text-black px-4 py-2 rounded mr-5"
              onClick={() => handleCancelDeleteMatter(onClose)}
            >
              No, cancel
            </button>
            <button
              className="bg-bgCol-danger-default text-white px-4 py-2 rounded mr-2"
              onClick={() => handleDeleteMatter(matter, onClose, onDelete)}
            >
              Yes, delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

DeleteMatterModal.propTypes = {
  matter: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DeleteMatterModal

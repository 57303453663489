import { action, runInAction } from 'mobx'

// This should be the contents of a .sprockets-manifest file.

/*
  {
    "files": {
      "application-7a076aad3ab7dc63482db2523016b883a8011f55541db5b1942d49af9eb10cf5.js": {
        "logical_path": "application.js",
        "mtime": "2023-06-15T04:48:02+00:00",
        "size": 1975360,
        "digest": "7a076aad3ab7dc63482db2523016b883a8011f55541db5b1942d49af9eb10cf5",
        "integrity": "sha256-egdqrTq33GNILbJSMBa4g6gBH1VUHbWxlC1Jr56xDPU="
      },

      ...

    },
    "assets": {
      "application.js": "application-7a076aad3ab7dc63482db2523016b883a8011f55541db5b1942d49af9eb10cf5.js",

      ...

    }
  }
*/

const getManifest = action(async (store) => {
  try {
    const result = await fetch(`/sprockets_manifest.json`)

    if (result.status === 200) {
      const responseData = await result.json()
      runInAction(() => {
        store.manifest = responseData
      })
    } else if (result.status === 404) {
      // We expect this request to succeed in production but not in development.
      return
    }

    return result
  } catch (e) {
    console.error(e)
    throw e
  }
})

export default getManifest

import React from 'react'

const ArrowLeftToLine = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 12.5L21 12.5M7 12.5L13 6.5M7 12.5L13 18.5M3 19.5V5.5"
      stroke="#1B212D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeftToLine

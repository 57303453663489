import { List } from '@material-tailwind/react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import MattersListItem from './MattersListItem'
import addFlash from '../../actions/AddFlash'
import { setInputValue } from '../../actions/InputValueActions'
import { setMatters, setSelectedMatter } from '../../actions/matterActions'
import { deleteMatter, undoDeleteMatter } from '../../apis/mattersApi'
import { getMessageObject } from '../../LocalStorageUtil'
import { rollbarConfig } from '../../rollbarConfig'
import { Matter, Store } from '../../Store'
import { useStore } from '../../useStore'
import NoResultsFound from '../NoResultsFound'

/**
 * Group matter by date key helper function
 * @param store
 */
const groupMatters = (store: Store) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)

  const matters = store.matters
  const now = dayjs().tz(
    store.user?.iana_timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const groupOrder =
    store.sortParams?.get('sortBy')?.includes('_at') &&
    store.sortParams.get('sortOrder') === 'asc'
      ? ['Older', 'This Month', 'This Week', 'Yesterday', 'Today']
      : ['Today', 'Yesterday', 'This Week', 'This Month', 'Older']

  const groups = groupOrder.reduce(
    (acc: { [key: string]: Matter[] }, group) => {
      acc[group] = []
      return acc
    },
    {}
  )
  matters.forEach((matter: Matter) => {
    const sortKey =
      store.sortParams?.get('sortBy') === 'created_at'
        ? 'created_at'
        : 'updated_at'
    const dateAt = dayjs(matter[sortKey]).tz(
      store.user?.iana_timezone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    const groupKey = dateAt.isSame(now, 'day')
      ? 'Today'
      : dateAt.isSame(now.subtract(1, 'day'), 'day')
        ? 'Yesterday'
        : dateAt.isAfter(now.subtract(1, 'week'))
          ? 'This Week'
          : dateAt.isAfter(now.subtract(1, 'month'))
            ? 'This Month'
            : 'Older'

    groups[groupKey].push(matter)
  })

  return groups
}

const MattersList = observer(() => {
  const navigate = useNavigate()
  const store = useStore()
  const groupedMatters = groupMatters(store)

  const handleMatterClick = action((matter: Matter) => {
    if (store.selectedMatter?.id !== matter.id) {
      setSelectedMatter(store, matter)
      setInputValue(store, getMessageObject(matter.id))
      // setPageTitle(store)
      navigate(`/chat/${matter.id}`)
    }
  })

  const handleUndoDeleteMatterClick = action((matter: Matter) => {
    undoDeleteMatter(store, matter.id)
      .then(
        action(() => {
          setMatters(store)
          store.flashes = []
          addFlash(store, 'success', `Action reversed.`)
          if (store.selectedMatter === null) {
            setSelectedMatter(store, matter)
            navigate(`/chat/${matter.id}`)
          }
        })
      )
      .catch((error) => {
        rollbarConfig(store)?.error(error)
      })
  })

  const handleDeleteClick = async (matter: Matter) => {
    deleteMatter(store, matter.id)
      .then(
        action(() => {
          addFlash(
            store,
            'notice',
            `${matter.title} was successfully deleted!`,
            {
              subType: 'deleteMatter',
              matter,
              onDeleteMatterClick: handleUndoDeleteMatterClick,
            }
          )
          store.matters = store.matters.filter(
            (aMatter) => aMatter.id !== matter.id
          )
          if (store.selectedMatter?.id === matter.id) {
            setSelectedMatter(store, null)
            navigate('/chat')
          }
        })
      )
      .catch((error) => {
        rollbarConfig(store)?.error(error)
      })
  }

  return store.matters.length > 0
    ? Object.keys(groupedMatters).map((group: string) =>
        groupedMatters[group].length > 0 ? (
          <div key={group}>
            <div className="h-8 p-2 text-txt-default-tertiary text-xs leading-default content-center">
              {group}
            </div>
            <List className="p-0 min-w-full" key={group}>
              {groupedMatters[group].map((matter: Matter) => (
                <MattersListItem
                  key={`matter-list-item-${matter.id}`}
                  matter={matter}
                  isSelected={matter.id === store.selectedMatter?.id}
                  onMatterClick={handleMatterClick}
                  onDeleteClick={handleDeleteClick}
                />
              ))}
            </List>
          </div>
        ) : null
      )
    : store.filterParams !== undefined && <NoResultsFound />
})

export default MattersList

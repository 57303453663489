import { replaceAlexiTextWithLogo } from '../../../actions/messagesActions'
import { useStore } from '../../../useStore'
import Accordion from '../../shared/Accordion'

export const EmptyMemoMessage = () => {
  const store = useStore()
  const messageContent = `Absolutely! Type your question below. Alexi will identify relevant caselaw and legislation. Then we'll prepare a memo that answers your question.`
  const sanitizedMessage = replaceAlexiTextWithLogo(messageContent, store)

  return (
    <div>
      {/* Render sanitized dynamic content */}
      <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />

      <Accordion
        title="How to write a good research question."
        body={
          <div>
            <div className="mt-2 text-[16px]">
              <p className="mt-2 font-normal">
                For best results, research questions should be objective and
                encompass one question.{' '}
              </p>
              <p className="mt-2 font-medium">
                Example of a well-framed research question:
              </p>
              <p className="mt-2 italic font-normal">
                In what circumstances will a court grant a request to limit the
                discovery of electronically stored information to certain
                keyword search terms?{' '}
              </p>
            </div>
          </div>
        }
        open={false}
      />
    </div>
  )
}

import PropTypes from 'prop-types'
import React from 'react'

import { showModal } from '../../../actions/modalActions'
import { useStore } from '../../../useStore'
import MemoDocument from '../../assets/MemoDocument'

const MemoMessage = ({ message, containerWidth }) => {
  const store = useStore()

  const handleViewMemo = () => {
    showModal(store, {
      contentType: 'InstantMemo',
      contentId: message?.instant_memo_id,
    })
  }

  const memoTitle = (title) => {
    const maxChars =
      containerWidth > 50 ? Math.floor(containerWidth / 9) : Infinity

    if (title.length > maxChars) {
      return `${title.substring(0, maxChars - 3)}...`
    } else {
      return title
    }
  }

  return (
    <>
      <div
        className="relative max-w-full flex flex-col bg-white items-start rounded-lg cursor-pointer border border-brd-neutral-default transition-all overflow-hidden"
        onClick={handleViewMemo}
      >
        <div className="flex items-center w-full p-3 rounded-md">
          <div className="p-2 bg-bgCol-neutral-secondary rounded-md flex items-center justify-center">
            <MemoDocument />
          </div>
          <div className="ml-2 min-w-0">
            <h3
              className={`text-sm font-semibold text-txt-default-default truncate max-w-[${containerWidth}px]`}
            >
              {memoTitle(message?.instant_memo_title)}
            </h3>
            <p className="text-xs text-txt-default-secondary truncate max-w-[200px]">
              Research memo
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

MemoMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string,
    matter_id: PropTypes.string.isRequired,
    instant_memo_id: PropTypes.string,
    instant_memo_title: PropTypes.string,
  }).isRequired,
  containerWidth: PropTypes.number.isRequired,
}

export default MemoMessage

import React from 'react'

import assetPath from '../assetPath'
import { useStore } from '../useStore'

interface FilesDeletedModalProps {
  handleTalkToSpecialistClick: () => void
  handleAddCreditCardClick: () => void
}

const FilesDeletedModal: React.FC<FilesDeletedModalProps> = ({
  handleTalkToSpecialistClick,
  handleAddCreditCardClick,
}) => {
  const store = useStore()
  return (
    <div className="fixed inset-0 bg-opacity-80 bg-gradient-to-b from-[#F6F6FF] to-white flex items-center justify-center z-50">
      <div className="absolute top-6 left-6">
        <a href="/">
          <img
            src={assetPath(store, 'alexiTitleLogo.svg')}
            alt="Alexi Logo"
            className="h-8 w-auto"
          />
        </a>
      </div>

      <div className="bg-white p-10 absolute top-20 rounded-lg shadow-lg w-[652px]">
        <div className="mb-8 ml-2">
          <h2 className="text-[32px] leading-[40px] font-bold text-align-center">
            All of your files have been deleted
          </h2>
        </div>

        <div className="text-base mb-8 font-normal text-align-center ml-3">
          Your trial has ended, and your files are deleted. Add a credit card or
          speak with a product specialist to keep using our litigation platform.
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center w-full space-y-4 sm:space-y-0 sm:space-x-4 lg:space-x-6">
          <button
            onClick={handleTalkToSpecialistClick}
            className="bg-bgCol-neutral-default text-txt-neutral-default flex items-center justify-center w-full sm:w-auto px-6 py-3 h-12 rounded-lg font-medium"
          >
            <img
              src={assetPath(store, 'trent.png')}
              alt="Product Specialist"
              className="w-8 h-8 rounded-full mr-2"
            />
            <span className="text-txt-neutral-default font-medium whitespace-nowrap">
              Talk to a product specialist
            </span>
          </button>
          <button
            onClick={handleAddCreditCardClick}
            className="bg-bgCol-brand-default text-white flex items-center justify-center w-full sm:w-auto px-6 py-3 h-12 rounded-lg font-medium"
          >
            Add a credit card
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilesDeletedModal

import React from 'react'

import EraserIcon from '../../assets/EraserIcon'

interface ClearButtonProps {
  isEnabled: boolean
  onClick: () => void
}

const ClearButton: React.FC<ClearButtonProps> = ({ isEnabled, onClick }) => {
  return (
    <button
      className={`flex items-center space-x-1 text-sm ${isEnabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
      disabled={!isEnabled}
      onClick={onClick}
      aria-label="Clear text"
    >
      <EraserIcon color={isEnabled ? '#5133EA' : '#A0AEC0'} />
      <span
        className={
          isEnabled ? 'text-brd-brand-default' : 'text-txt-disabled-default'
        }
      >
        Clear
      </span>
    </button>
  )
}

export default ClearButton

import axios from 'axios'
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import FormStep from './FormStep'
import { submitOnboardingForm } from '../../apis/onboardingApi'
import SelectInput from '../shared/SelectInput'

const isValidUrl = (url: string) => {
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*\/?$/
  return urlPattern.test(url)
}

interface FormData {
  firm_name: string
  firm_website: string
  law_firm_type: string
  firm_size: string
}

const AboutLawFirmStep: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      firm_name: '',
      firm_website: '',
      law_firm_type: '',
      firm_size: '',
    },
    mode: 'onChange',
  })

  // Fetch initial data and set default values
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/client/v1/onboardings/firm_data')
        reset(response.data) // Set default values using reset
      } catch (error) {
        console.error('Error fetching firm data:', error)
      }
    }

    fetchData()
  }, [reset])

  const onSubmit = async (data: FormData) => {
    await submitOnboardingForm(
      '/api/client/v1/onboardings/complete_firm_info',
      data
    )
  }

  const lawFirmType = watch('law_firm_type')

  const getFirmSizeLabel = () => {
    if (
      ['corporation', 'goverment', 'non_profit'].includes(lawFirmType || '')
    ) {
      return 'What is the size of your legal department?*'
    }
    return 'What is the size of your firm?*'
  }

  const handleNotPartOfFirm = async () => {
    await submitOnboardingForm(
      '/api/client/v1/onboardings/not_part_of_firm',
      {}
    )
  }

  return (
    <FormStep step={1} totalSteps={3} title="Tell us about your law firm">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {/* Firm Name */}
        <div>
          <label htmlFor="firm_name" className="block text-sm font-medium mb-2">
            Firm name*
          </label>
          <input
            id="firm_name"
            {...register('firm_name', {
              required: 'Firm name is required',
              minLength: {
                value: 2,
                message: 'Firm name is required',
              },
              pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message:
                  'Firm name can only contain letters, numbers, and spaces',
              },
            })}
            className={`h-10 mt-1 block w-full border rounded-md px-4 py-2 focus:ring-1 focus:outline-none focus:ring-brd-brand-default focus:border-brd-brand-default ${
              errors.firm_name
                ? 'border-brd-danger-default'
                : 'border-brd-default-default'
            }`}
            placeholder="Firm name"
          />
          {errors.firm_name && (
            <p className="mt-1 text-txt-danger-default text-sm">
              {errors.firm_name.message}
            </p>
          )}
        </div>

        {/* Firm Website */}
        <div>
          <label
            htmlFor="firm_website"
            className="block text-sm font-medium mb-2"
          >
            Firm website*
          </label>
          <input
            id="firm_website"
            {...register('firm_website', {
              required: 'Please enter a valid URL for your website.',
              minLength: {
                value: 4,
                message: 'Please enter a valid URL for your website.',
              },
              validate: (value) =>
                isValidUrl(value) ||
                'Please enter a valid URL for your website.',
            })}
            className={`h-10 mt-1 block w-full border rounded-md px-4 py-2 focus:ring-1 focus:outline-none focus:ring-brd-brand-default focus:border-brd-brand-default ${
              errors.firm_website
                ? 'border-brd-danger-default'
                : 'border-brd-default-default'
            }`}
            placeholder="Firm website"
          />
          {errors.firm_website && (
            <p className="mt-1 text-txt-danger-default text-sm">
              {errors.firm_website.message}
            </p>
          )}
        </div>

        {/* Law Firm Type */}
        <div>
          <label
            htmlFor="law_firm_type"
            className="block text-sm font-medium mb-2"
          >
            What type of law firm do you work for?*
          </label>
          <Controller
            name="law_firm_type"
            control={control}
            rules={{ required: 'Please select a type of law firm' }}
            render={({ field }) => (
              <SelectInput
                {...field}
                options={{
                  'Private practice': 'Private practice',
                  Corporation: 'Corporation',
                  Government: 'Government',
                  'Non-profit': 'Non-profit',
                }}
              />
            )}
          />
          {errors.law_firm_type && (
            <p className="mt-1 text-txt-danger-default text-sm">
              {errors.law_firm_type.message}
            </p>
          )}
        </div>

        {/* Firm Size */}
        <div>
          <label htmlFor="firm_size" className="block text-sm font-medium mb-2">
            {getFirmSizeLabel()}
          </label>
          <Controller
            name="firm_size"
            control={control}
            rules={{ required: 'Please select the size of your firm' }}
            render={({ field }) => (
              <SelectInput
                {...field}
                options={{
                  '1-2': '1-2',
                  '3-10': '3-10',
                  '11-50': '11-50',
                  '51-100': '51-100',
                  '100+': '100+',
                }}
              />
            )}
          />
          {errors.firm_size && (
            <p className="mt-1 text-txt-danger-default text-sm">
              {errors.firm_size.message}
            </p>
          )}
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            disabled={!isValid}
            className="h-11 text-center disabled:cursor-not-allowed disabled:bg-bgCol-disabled-default disabled:text-txt-disabled-default w-full flex items-center justify-center py-2 px-4 rounded-md text-white bg-bgCol-brand-default focus:ring-2 focus:ring-offset-2"
          >
            Continue
          </button>
        </div>
      </form>

      <div className="mt-16 text-center">
        <a
          href="#"
          className="text-txt-brand-default text-sm font-medium"
          onClick={handleNotPartOfFirm}
        >
          I am not part of a law firm
        </a>
      </div>
    </FormStep>
  )
}

export default AboutLawFirmStep
